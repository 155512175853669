/* Example of setting the font family */
body {
  font-family: 'Arial', sans-serif; /* Change 'Arial' to your desired font */
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'sportz'; /* Change 'Roboto' to your desired font */
}

p {
  font-family: 'dosis-me'; /* Change 'Open Sans' to your desired font */
  margin: auto;
  font-size: 20px;
  text-align: center;
  color: black;
}

/* Header */
header ul {
  list-style: none;
  padding: 2% 2%;
  display: flex;
  justify-content: space-around;
  vertical-align: middle;
}

header ul a {
  font-family: 'sportz';
  color: #ffffff;
  font-weight: 500;
  font-size: 116%;
  padding: 10px 13px;
  text-decoration: none;
  transition: all 0.5s;
}

header ul a:hover {
  color: rgba(131, 220, 255, 0.692);
}

header ul li {
  display: flex;
  vertical-align: middle;
}

/* Container */
.containero {
  display: flex;
  flex-direction: column;
  background-color: #8f8f8f !important;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.containero img {
  width: 100%;
  height: auto;
  margin-bottom: 20px; /* Add space between image and text */
}

.containero p {
  overflow: hidden;
  color: white;
  font-family: 'dosis-me';
  font-size: 20px;
  text-overflow: ellipsis;
  line-clamp: 3; /* Limit to 3 lines of text */
  -webkit-line-clamp: 3; /* For Safari */
  -webkit-box-orient: vertical; /* For Safari */
}

.containero h1 {
  font-size: 220%;
  font-family: 'dosis-me';
  font-size: 20px;
  color: white;
}

.containero h2 {
  padding-top: 1%;
  font-size: 170%;
  font-family: 'dosis-me';
  color: rgb(46, 0, 0);
}

/* Main Content */

main {
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  background: transparent;
}

/* Front Section */
#front {
  height: auto;
  background-image: url('https://slidebazaar.com/wp-content/uploads/2022/05/powerpoint-slide-with-white-background-SB02298.jpg');
  padding: 13% 0%;
  text-align: center;
}

#front h1 {
  font-family: 'sportz' !important;
  color: rgb(0, 0, 0);
  font-size: 350%;
}

#front p {
  margin-top: -8%;
  text-align: center;
  color: rgb(0, 0, 0);
  padding: 60px 10%;
  font-size: 20px;
  font-family: 'dosis-me' !important;
  letter-spacing: 0.04cm;
}

#front img {
  margin-top: -5%;
  height: 40%;
  width: 40%;
}

/* First Section */
#first {
  width: 90%;
  height: auto;
  padding: 5% 5%;
  background: transparent;
  font-family: 'sportz';
  display: flex;
  justify-content: space-between;
}

#first div {
  text-align: left;
}

#first p {
  font-size: 120%;
  padding-top: 3%;
  color: rgb(0, 0, 0);
}

#first h2 {
  padding: 2%;
  padding-top: 4%;
}

#first h1 {
  padding: 0;
}

/* Second Section */
#second {
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
}

.containeros {
  text-align: center; /* Center the text within its container */
}
.containeros img {
  width: 550px;
  height: auto;
  margin-bottom: 20px;
}

.imm {
  display: block; /* Ensure the image is treated as a block element */
  margin: auto; /* Center the image horizontally */
}


/* Third Section */
#third {
  width: 100%;
  height: auto;
  margin-top: 5%;
  background-image: url('https://slidebazaar.com/wp-content/uploads/2022/05/powerpoint-slide-with-white-background-SB02298.jpg');
  padding: 5% 0%;
  background-position: center top; /* Move the background image to the center top */
}

#third h6,
h6 {
  color: rgb(0, 0, 0);
  font-family: 'dosis-me';
  font-size: 20px;
}

#third_cards {
  display: flex;
  justify-content: space-around;
  padding: 5%;
}

#third_cards div {
  margin: 0% 2%;
  padding: 2% 2%;
  background: #000000;
  border-radius: 20px;
  line-height: 160%;
  font-family: 'dosis-me';
}

#third_cards div:hover {
  color: white;
}

#third_cards div:before {
  content: "";
  position: absolute;
  margin-top: -28px;
  height: 41%;
  width: 26.5%;
  margin-left: -23px;
  border-radius: 20px;
  z-index: 1;
  opacity: 0.2;
}

#third_cards div:hover:before {
  background: #8f8f8f;
}

#third_cards div h2 {
  padding: 8% 0px;
  font-size: 140%;
  text-align: center;
  color: rgb(255, 255, 255);
}

#third_cards div p {
  font-size: 120%;
  text-align: center;
  color: rgb(234, 255, 247);
}

/* Fourth Section */
#fourth {
  height: auto;
  width: 100%;
  padding: 4% 0%;
  background-image: url('https://slidebazaar.com/wp-content/uploads/2022/05/powerpoint-slide-with-white-background-SB02298.jpg');
  font-family: 'dosis';
}

#fourth h2,
h1 {
  text-align: center;
  padding: 2% 20%;
}

#fourth_cards {
  display: flex;
  justify-content: space-evenly;
  padding: 1% 5%;
}

#fourth_cards div {
  margin: 0% 1%;
  padding: 5% 5%;
  color: rgb(0, 0, 0);
  box-shadow: 0px 0px 10px 0px rgba(29, 29, 29, 0.801);
  background: #ffffff;
  text-align: center;
  transition: all 0.5s ease;
}

#fourth_cards div p {
  padding-top: 20px;
  font-weight: bolder;
}

#fourth_cards div:hover {
  background: #000000;
  color: #ffffff;
  border-bottom-right-radius: 10%;
  border-left: 2px rgb(255, 255, 255) solid;
  border-bottom: 2px rgb(255, 255, 255) solid;
}

#fourth_cards div:hover p {
  color: #ffffff; /* Change text color to white when the card is hovered */
}

/* Fifth Section */
#fifth {
  background: #8f8f8f;
  padding: 50px 40px;
}

#fifth div {
  display: flex;
  justify-content: space-around;
}

#fifth a {
  display: flex;
  justify-content: space-around;
  color: white;
  text-decoration: none;
  margin: 0px 1%;
}

#fifth img {
  margin-right: 7%;
}

#fifth h1 {
  font-size: 230%;
  text-align: center;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  padding-bottom: 5%;
  color: rgb(255, 255, 255);
}

/* Footer */
#foote {
  display: flex;
  justify-content: space-around;
  background: #8f8f8f;
  padding: 0px 50px;
  padding-top: 20px;
}

#foote ul {
  height: auto;
  width: 100%;
  padding-left: 5%;
  list-style: none;
}

#foote ul li {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 110%;
  padding: 5% 2%;
  text-decoration: none;
}

#foote ul li a:before {
  content: "";
  width: 0%;
  margin-top: 25px;
  height: 2px;
  z-index: 1;
  position: absolute;
  background: #8f8f8f;
  transition: all 0.2s ease-in;
}

#foote li a:hover:before {
  width: 5.7%;
}

#foote div {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

#foote div h2 {
  padding: 5%;
  color: white;
  text-decoration: none;
  font-family: sans-serif;
}

#foote div span a {
  padding: 10px 10px;
  transition: all 2s;
}

#foote div span a img {
  padding: 0.5%;
  height: 80%;
  width: 10%;
}

#foote div span a :hover {
  transform: rotate(10deg);
}

/* Animation */
.reveal {
  transform: translateY(150px);
  opacity: 0;
  transition: all 2s ease;
  color: black;
}

.reveal.active {
  transform: translateY(0px);
  opacity: 1;
}

/* Responsive */
@media screen and (max-width: 768px) {
  #first {
    flex-direction: column;
    margin-left: 10px;
    align-items: center;
  }
  #first img{
    width: 100%;
    height: auto;
  }
  
  #first div {
    text-align: center;
  }
  
  #first p {
    padding-top: 2%;
  }
  
  #second {
    width: 100%;
    height: auto;
  }
  
  #second img{
    padding-top: 50px;
  }
  
  #third_cards {
    flex-wrap: wrap;
  }
  
  #third_cards div {
    width: 45%;
    margin: 2.5%;
  }
  
  #fourth_cards {
    flex-wrap: wrap;
  }
  
  #fourth_cards div {
    width: 45%;
    margin: 2.5%;
  }
  .comm {
    width: 100%;
    background: #8f8f8f;
    height: 2%;
    padding: 0.5%;
  }
  
  #comm1,
  #comm2,
  #comm3 {
    width: 0%;
    height: 100%;
    background: #ff0000;
    transition: all 5s ease;
  }
  
  .reveal.active #comm1,
  .reveal.active #comm2,
  .reveal.active #comm3 {
    width: 100%;
  }
}

/* Comment Section */
.comm {
  width: 100%;
  background: #8f8f8f;
  height: 2%;
  padding: 0.5%;
}

#comm1,
#comm2,
#comm3 {
  width: 0%;
  height: 100%;
  background: #ff0000;
  transition: all 5s ease;
}

.reveal.active #comm1,
.reveal.active #comm2,
.reveal.active #comm3 {
  width: 100%;
}
