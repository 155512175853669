/* In your CSS file (e.g., fonts.css) */
@font-face {
    font-family: 'sportz';
    src: url('./Sportzan.ttf') format('truetype'); /* Adjust the path and format as needed */
}

@font-face {
    font-family: 'dosis';
    src: url('./Dosis-Light.ttf') format('truetype'); /* Adjust the path and format as needed */
}

@font-face {
    font-family: 'dosis-me';
    src: url('./Dosis-Medium.ttf') format('truetype'); /* Adjust the path and format as needed */
}
@font-face {
    font-family: 'munich';
    src: url('./MUNICH\ SANS.ttf') format('truetype'); /* Adjust the path and format as needed */
}
@font-face {
    font-family: 'oneday';
    src: url('./ONEDAY.ttf') format('truetype'); /* Adjust the path and format as needed */
}