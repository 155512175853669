.registration-wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
}

.registration-inner {
  position: relative;
  width: 435px;
}

.registration-img-1 {
  position: absolute;
  bottom: -12px;
  left: -191px;
  z-index: 99;
}

.registration-img-2 {
  position: absolute;
  bottom: 0;
  right: -129px;
}

.registration-form {
  width: 100%;
  position: relative;
  z-index: 9;
  padding: 77px 61px 66px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.registration-heading {
  text-transform: uppercase;
  font-size: 25px;
  color: #333;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 33px;
}

.registration-input-holder {
  position: relative;
  margin-bottom: 21px;
}

.registration-input-holder span {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 15px;
  color: #333;
}

.registration-input {
  border: none;
  border-bottom: 1px solid #e6e6e6;
  display: block;
  width: 100%;
  height: 38px;
  background: none;
  padding: 3px 42px 0px;
  color: #666;
  font-size: 16px;
}

.registration-input::-webkit-input-placeholder {
  font-size: 14px;
  color: #999;
}

.registration-input:focus {
  border-bottom: 1px solid #accffe;
}

.registration-btn {
  border: none;
  width: 100%;
  height: 49px;
  margin-top: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: #000000;
  color: #fff;
  text-transform: uppercase;
  font-size: 25px;
  letter-spacing: 2px;
  transition: all 0.5s;
  position: relative;
  overflow: hidden;
}

.registration-btn span {
  position: relative;
  z-index: 2;
}

.registration-btn:before,
.registration-btn:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(141, 141, 141, 0.25);
  transition: all 0.3s;
  transform: translate(-100%, 0);
}

.registration-btn:after {
  transition-delay: 0.2s;
}

.registration-btn:hover:before,
.registration-btn:hover:after {
  transform: translate(0, 0);
}

@media (max-width: 991px) {
  .registration-inner {
    width: 400px;
    left: 4%;
  }
}

@media (max-width: 767px) {
  .registration-inner {
    width: 100%;
    left: 0;
  }

  .registration-img-1,
  .registration-img-2 {
    display: none;
  }

  .registration-form {
    padding: 35px;
    box-shadow: none;
  }
}
