/* Base styles for the navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 50%; /* Center the navbar horizontally */
  transform: translateX(-50%);
  width: 50%; /* Adjust the width as needed */
  background-color: #000000;
  padding: 10px 0;
  border-bottom-left-radius: 390px; /* Adjust the curve radius */
  border-bottom-right-radius: 390px; /* Adjust the curve radius */
  z-index: 1001;
}

.mobile-nav {
  display: none; /* Hide the mobile navbar by default */
}

.desktop-nav {
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center;
  width: 100%; /* Ensure full width */
}

.navbar-link {
  color: #ffffff;
  text-decoration: none;
  font-family: 'oneday';
  font-size: 16px;
  padding: 10px 20px;
  transition: all 0.3s ease;
}

.navbar-link:hover {
  color: #4f8592;
  text-decoration: none;
}

/* Dropdown styles */
.dropdown-content {
  background-color: none;
  padding: 10px;
  display: none;
}

.dropdown-content.open {
  display: flex;
  flex-direction: column; /* Display dropdown content vertically */
}

/* Arrow icon */
.fa-chevron-down {
  margin-left: 5px;
}

/* Media query for screens smaller than 768px */
@media screen and (max-width: 768px) {
  .desktop-nav {
    display: none; /* Hide the desktop navbar on small screens */
  }

  .mobile-nav {
    display: flex; /* Show the mobile navbar on small screens */
    flex-direction: column; /* Display content vertically */
    align-items: center; /* Center content horizontally */
    width: 100%; /* Adjust the width to fit the screen */
  }

  .navbar-link {
    text-align: center; /* Center text */
    width: 100%; /* Make links full width */
    margin-bottom: 10px; /* Add margin between links */
  }

  .dropdown-content {
    width: 100%; /* Make dropdown full width */
  }
}
@media screen and (max-width: 1024px) {
  .desktop-nav {
    display: none; /* Hide the desktop navbar on small screens */
  }

  .mobile-nav {
    display: flex; /* Show the mobile navbar on small screens */
    flex-direction: column; /* Display content vertically */
    align-items: center; /* Center content horizontally */
    width: 100%; /* Adjust the width to fit the screen */
  }

  .navbar-link {
    text-align: center; /* Center text */
    width: 100%; /* Make links full width */
    margin-bottom: 10px; /* Add margin between links */
  }

  .dropdown-content {
    width: 100%; /* Make dropdown full width */
  }
}