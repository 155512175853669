.MuiBox-root.css-1v1qs4 {
    background-color: rgb(238, 238, 238);
}
h2.MuiTypography-root.MuiTypography-h4.css-wq350c-MuiTypography-root {
    background-color: rgb(238, 238, 238);
}
@media (max-width: 600px) {
   
        .css-juettb {
            padding: 30px;
            margin-left: 10px;
            margin-top: 60px;
        }
    }