.header {
  display: flex;
  justify-content: center; /* Center the contents horizontally */
  align-items: center;
  background: #3c3c3c; 
  padding-top: 75px;
  position: relative; /* Add position relative */
}
  
  .header h1 {
    color: #eee;
    font-size: 20px;
    font-family: 'sportz';
    justify-content: center; /* Center the contents horizontally */
    align-items: center;
    text-transform: uppercase;
   
  }
  
  .faqs {
    width: 80%;
    margin: 0 auto;
    padding: 15px;
  }
  
  .faqs .faq {
    margin: 15px;
    padding: 15px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  }
  
  .faqs .faq .faq-question {
    position: relative;
    font-size: 20px;
    font-family: 'dosis-me';
    padding-right: 80px;
    transition: all 0.4s ease;
  }
  
  .faqs .faq .faq-question::after {
    content: "+";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    transition: all 0.2s ease;
  }
  
  .faqs .faq .faq-answer {
    opacity: 0;
    max-height: 0;
    font-family: 'dosis';
    font-size: large;
    overflow: hidden;
    transition: all 0.2s ease;
  }
  
  .faqs .faq.open .faq-question {
    margin-bottom: 15px;
  }
  
  .faqs .faq.open .faq-question::after {
    content: "-";
  }
  
  .faqs .faq.open .faq-answer {
    max-height: 1000px;
    opacity: 1;
  }
  .back-button {
    position: absolute;
    left: 10px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
    font-size: 20px;
    padding: 20px 20px 20px;
    padding-top: 40px;
    transition: all 0.3s ease;
  }
  
  .back-button:hover {
    color: #7ac9f7;
  }
  
  .back-icon {
    margin-right: 10px;
  }
  .imaga{
    width: 150px;
    position: relative;
    left: -250px;
    top: -70px;
  }
   
  @media screen and (max-width: 768px) {
    .imaga {
      left: -40px !important; /* Move the logo to the center horizontally */
      transform: translateX(-50%); /* Adjust the position to center */
      top: -46px; /* Maintain the top position */
      width: 80px !important;
    }
    .header h1 {
      color: #eee;
      font-size: 20px;
      font-family: 'sportz';
      margin-left: -70px !important;
      text-transform: uppercase;
      justify-content: center; /* Center the contents horizontally */
      align-items: center;
    }
  }
  @media screen and (max-width: 1024px) {
    .header h1 {
      color: #eee;
      font-size: 20px;
      font-family: 'sportz';
      margin-left: -100px !important;
      text-transform: uppercase;
      justify-content: center; /* Center the contents horizontally */
      align-items: center;
    }
    .imaga {
      left: -100px; /* Move the logo to the center horizontally */
      transform: translateX(-50%); /* Adjust the position to center */
      top: -46px; /* Maintain the top position */
      width: 100px;
    }
  }