.pagee {
  background-image: url('../img/bg\ main.png');
  background-position:center;
  background-size:cover;
  flex-shrink: 0;
  width: 100vw;
  height: 100vh;
  
}
.page1 {
  background-image: url('../img/Untitled\ design.jpg');
  background-position:center;
  background-size:cover;
  flex-shrink: 0;
  width: 100vw;
  height: 150vh;
}
.page2 {
  background-image: url('../img/Untitled\ design\ \(1\).jpg');
  background-position:center;
  background-size:cover;
  flex-shrink: 0;
  width: 100vw;
}
.timelineimg img{
  width: 200px;
}
/* styles.css */

/* styles.css */

:root {
  --white: #fffefe;
  --light-grey: #edf0f1;
  --violet: #ffffff;
  --dark-violet: #a9a9a9;
  --black: #000000;
}


ul {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

/* .bodyyyyyyy {
  font-family: "Inter", sans-serif;
  text-align: center;
  padding: 0 20px;
  background: var(--light-grey);
  color: var(--white);
} */

.notification {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  background: var(--violet);
}

/* MAIN STYLES */
.main-nav {
  position: fixed;
  display: flex;
  justify-content: center;
  max-width: 400px;
  top: 40px;
  z-index: 9999;
  font-family: 'oneday';
}

.main-nav [type="checkbox"] {
  position: absolute;
  left: -9999px;
}

.main-nav [type="checkbox"] + label {
  position: relative;
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  cursor: pointer;
  z-index: 1;
  background: black;
  border-radius: 50%;
  transform: translateY(-50%);
  transition: all 0.2s;
  color: white;

}

.main-nav [type="checkbox"] + label:hover {
  background: var(--dark-violet);
}

.menu-items li {
  position: absolute;
  top: -25px;
  left: 30%;
  transform: translateX(-50%);
  transition: all 0.4s;
  gap:50px !important;
}

.menu-items li:nth-child(1) {
  transition-delay: 0.2s;
}

.menu-items li:nth-child(2) {
  transition-delay: 0.15s;
}

.menu-items li:nth-child(3) {
  transition-delay: 0.1s;
}

.menu-items li:nth-child(4) {
  transition-delay: 0.05s;
}

.menu-items li a {
  width: 57px;
  height: 57px;
  border-radius: 50%;
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  background: var(--violet);
  gap:70%;
  
}

.menu-items li a span {
  position: absolute;
  margin-top: -18px;
  left: 16%;
  transform: translateY(calc(-100% - 5px));
  width: 100%;
  font-size: 17px;
  white-space: nowrap;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
  color: balck;
  font-weight: normal;
  font-family: 'dosis' ;
}

.main-nav input:checked + label {
  background: var(--black);
  transform: translateY(calc(-50% + 4px));
}

.main-nav input:checked ~ .menu-items li:nth-child(1) {
  top: 70px;
  left: -30px;
  transition-delay: 0.1s;
}

.main-nav input:checked ~ .menu-items li:nth-child(2) {
  top: 90px;
  left: 40px;
  transition-delay: 0.2s;
}

.main-nav input:checked ~ .menu-items li:nth-child(3) {
  top: 5px;
  left: 146px;
  transition-delay: 0.3s;
}

.main-nav input:checked ~ .menu-items li:nth-child(4) {
  top: 70px;
  left: 110px;
  transition-delay: 0.4s;
}


.main-nav input:checked ~ .menu-items li a span {
  opacity: 1;
  transition-delay: 0.9s;
}
.menu-items li:nth-child(5) {
  transition-delay: 0s;
}

.main-nav input:checked ~ .menu-items li:nth-child(5) {
  top: 5px;
  left: -70px;
  transition-delay: 0.5s;
}

.menu-items li:nth-child(5) a span {
  color: black;
}

/* navbar end */

/* heading */
.main-sub-heading{
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;  
  align-items: flex-start;
  padding-top: 150px;
}

.main-sub-heading p {
  border-radius: 20px;
  font-size: 70px;
  font-weight: bold;
  font-family: 'munich';
}

/* heading end */
.my-button {
  width: 190px;
  padding: 0;
  border: none;
  transform: rotate(5deg);
  transform-origin: center;
  text-decoration: none;
  font-size: 35px;
  cursor: pointer;
  padding-bottom: 3px;
  border-radius: 5px;
  box-shadow: 0 2px 0 #494a4b;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background-color: #c8d8d0;
}

.my-button__text {
  background: #f1f5f8;
  display: block;
  border-radius: 5px;
  border: 2px solid #494a4b;
  text-align: center;
  font-family: 'oneday';
}

.my-button:active {
  transform: translateY(5px);
  padding-bottom: 0px;
  outline: 0;
}
.blog-title h4 {
  font-size: 30px;
  font-weight: 500;
  font-family: 'munich';
  margin-bottom: -15px;
}


.fa-blogger:hover {
  color: blue; /* Change to the desired color */
}

.fa-user-group:hover {
  color: blue; /* Change to the desired color */
}

.fa-book:hover {
  color: blue; /* Change to the desired color */
}

.fa-hand-holding-dollar:hover {
  color: blue; /* Change to the desired color */
}

.fa-house:hover {
  color: blue; /* Change to the desired color */
}

/* footer start */
.footer {
    background:linear-gradient(rgb(255, 255, 255), #b8b8b8);
    color: #000000;
}
/* Style for the footer logo container */
.footer-logo {
  text-align: center; /* Align the content in the center */
  margin-top: -70px;
}

/* Style for the footer logo link */
.footer-logo-link {
  text-decoration: none; /* Remove underline from the link */
}

/* Style for the logo image */
.footer-logo img {
  max-width: 25%; /* Ensure the image does not exceed the container's width */
  height: auto; /* Maintain aspect ratio */
  padding-bottom: 90px;
  padding-top: 30px;
  
}
@media only screen and (max-width: 768px) {
  .footer-logo img {
      max-width: 80%; /* Reduce the width of the logo for smaller screens */
  }
  .page1 {
    background-image: url('../img/Untitled\ design.jpg');
    background-position:center;
    background-size:cover;
    flex-shrink: 0;
    width: 100vw;
    height: 150vh;
  }
  .footer-card {
    flex-basis: calc(50% - 20px); /* Adjusted flex-basis to ensure cards take up half of the container width with spacing */
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px !important;
    box-sizing: border-box; 
  }
}

@media only screen and (max-width: 480px) {
  .footer-logo img {
      max-width: 60%; /* Further reduce the width of the logo for smaller screens */
  }
  
}

.footer-cards-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: -50px;
}
.footer-cards-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: -50px;
}

.footer-card {
  flex-basis: calc(50% - 20px); /* Adjusted flex-basis to ensure cards take up half of the container width with spacing */
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box; 
}

.footer-card:hover {
  background-color: #000000; /* Change background color of cards */
  color: #ffffff; /* Change text color of cards */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adjust box shadow */
}

.footer-card-header{
  font-family: 'dosis-me';
  font-weight: bold;
}
.footer-card-content {
  font-family: 'dosis';
  color: black;
}

.footer-card-header:hover,
.footer-card-content:hover {
  color: #ffffff; /* Change text color of card headers and content */
}
.footer-card:hover {
  background-color: #000000;
  color: #ffffff; /* Change text color to white */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box; 
}

.footer-card:hover .footer-card-header,
.footer-card:hover .footer-card-content {
  color: #ffffff; /* Change text color to white */
}

.footer-card-image {
  width: 80px; /* Adjust size as needed */
  margin-bottom: 10px;
}
.footer-card:hover .footer-card-image {
  filter: brightness(0) invert(1); /* Change image color to white */
}

.close-icon {
  cursor: pointer; /* Change cursor to pointer on hover */
  color: #333; /* Change color as needed */
  font-size: 16px; /* Adjust font size */
  position: absolute; /* Set position to absolute */
  top: 5px; /* Adjust top position */
  right: 5px; /* Adjust right position */
}
.close-button {
  background: none; /* Remove background */
  border: none; /* Remove border */
  padding: 0; /* Remove padding */
}

.close-icon:hover {
  color: red; /* Change color on hover */
}

.footer-card-button {
  display: block;
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #000000;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  font-family: 'dosis-me';
  text-decoration: none;
  transition: background-color 0.3s ease;
}
.footer-card:hover .footer-card-button {
  background-color: #ffffff; /* Change background color to white */
  color: #000000; /* Change text color to black */
}

@media (max-width: 768px) {
  .footer-cards-container {
    flex-direction: column;
  }
  .footer-bottom-link {
    font-size: 8px  !important;
    margin: 0 40px !important; /* Add some spacing between links */
    margin-bottom: -40px !important;
    
  }
  
}

.footer-bottom {
  background-color: #000000;
  padding: 20px 0;
  text-align: center; /* Center-align the content */
  padding-bottom: 30px;
}

.footer-bottom-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center-align the links */
  margin-bottom: 10px;
}

.footer-bottom-link {
  color: #fff;
  margin: 0 40px; /* Add some spacing between links */
  font-size: 14px;
  margin-bottom: -40px;
  cursor: pointer;
  font-family: 'dosis-me';
  transition: color 0.3s;
}

.footer-bottom-link:hover {
  color: #4bf762;
}

.footer-bottom-social-icons {
  display: flex;
  margin: 0 40px;
  justify-content: center; /* Center-align the social icons */
}

.footer-bottom-social-icon {
  margin-right: 10px; /* Add some spacing between icons */
}

.footer-bottom-social-icon img {
  width: 30px; /* Adjust icon size as needed */
  height: auto;
  margin: 0 10px;
  padding-bottom: 20px;
}

.footer-wave-svg {
    background-color: transparent;
    display: block;
    height: 90px;
    position: relative;
    top: -1px;
    width: 100%;
}
.footer-wave-path {
    fill: #ffffff;
}
.fa-brands.fa-blogger {
  /* height: 170px !important;
  width: 170px !important; */
}
.footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 140px;
    position: relative;
}

.footer-content-column {
    box-sizing: border-box;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    color: #000000;
}

.footer-content-column ul li a {
  color: #000000a8;
  text-decoration: none;
  font-size: 26px;
}

.footer-logo-link {
    display: inline-block;
}
.footer-menu {
    margin-top: 30px;
}
.footer-menu-name {
    color: #000000;
    font-size: 28px;
    font-weight: 900;
    letter-spacing: .1em;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
}
.footer-menu-list {
    list-style: none;
    margin-bottom: 0;
    margin-top: 10px;
    padding-left: 0;
}
.footer-menu-list li {
    margin-top: 5px;
}

.footer-call-to-action-description {
    color: #000000;
    margin-top: 10px;
    margin-bottom: 20px;
}
.footer-call-to-action-button:hover {
    background-color: #ffffffdd;
    color: #000000;
    text-decoration: none;
}
.button:last-of-type {
    margin-right: 0;
}
.footer-call-to-action-button {
    background-color: #000000;
    border-radius: 21px;
    color: #ffffff;
    display: inline-block;
    font-size: 26px;
    font-weight: 900;
    letter-spacing: .1em;
    line-height: 18px;
    padding: 12px 30px;
    margin: 0 10px 10px 0;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color .2s;
    cursor: pointer;
    position: relative;
}
.footer-call-to-action {
    margin-top: 30px;
}
.footer-call-to-action-title {
    color: #000000;
    font-size: 24px;
    font-weight: 900;
    letter-spacing: .1em;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
}
.footer-call-to-action-link-wrapper {
    margin-bottom: 0;
    margin-top: 10px;
    color: #fff;
    text-decoration: none;
}
.footer-call-to-action-link-wrapper a {
    color: #000000;
    text-decoration: none;
    font-size: 24px;
}

.footer-social-links {
    bottom: 0;
    height: 54px;
    position: absolute;
    right: 0;
    width: 236px;
}

.footer-social-amoeba-svg {
    height: 54px;
    left: 0;
    display: block;
    position: absolute;
    top: 0;
    width: 236px;
}

.footer-social-amoeba-path {
    fill: #d2d2d2;
}

.footer-social-link.linkedin {
    height: 26px;
    left: 3px;
    top: 11px;
    width: 26px;
}

.footer-social-link {
    display: block;
    padding: 10px;
    position: absolute;
}

.hidden-link-text {
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px,1px,1px,1px);
    -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
    clip-path: inset(0px 0px 99.9% 99.9%);
    overflow: hidden;
    height: 1px;
    width: 1px;
    padding: 0;
    border: 0;
    top: 50%;
}

.footer-social-icon-svg {
    display: block;
}

.footer-social-icon-path {
    fill: #fffff2;
    transition: fill .2s;
}

.footer-social-link.twitter {
    height: 28px;
    left: 62px;
    top: 3px;
    width: 28px;
}

.footer-social-link.youtube {
    height: 24px;
    left: 123px;
    top: 12px;
    width: 24px;
}

.footer-social-link.github {
    height: 34px;
    left: 172px;
    top: 7px;
    width: 34px;
}

.footer-copyright {
    background-color: #ffffff;
    color: #000000;
    padding: 15px 30px;
  text-align: center;
}

.footer-copyright-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
}

.footer-copyright-text {
    color: #000000;
    font-size: 24px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
}

.footer-copyright-link {
    color: #000000;
    text-decoration: none;
}

/* Media Query For different screens */
@media (min-width:320px) and (max-width:479px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 100px;
    position: relative;
  }
 
}
@media (min-width:480px) and (max-width:599px)  { /* smartphones, Android phones, landscape iPhone */
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 100px;
    position: relative;
  }
}
@media (min-width:600px) and (max-width: 800px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 100px;
    position: relative;
  }
}
@media (max-width:860px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */
  .page1 {
    background-image: url('../img/Untitled\ design.jpg');
    background-position:center;
    background-size:cover;
    flex-shrink: 0;
    width: 100vw;
    height: 120vh;
  }
}
@media (min-width:861px) and (max-width: 890px) { /* big landscape tablets, laptops, and desktops */
  .page1 {
    background-image: url('../img/Untitled\ design.jpg');
    background-position:center;
    background-size:cover;
    flex-shrink: 0;
    width: 100vw;
    height: 100vh;
  }
}
@media (min-width:1281px) { /* hi-res laptops and desktops */

}

@media (min-width: 760px) {
  .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 140px;
      position: relative;
  }

  .footer-wave-svg {
      height: 90px;
  }

  .footer-content-column {
      width: 24.99%;
  }
}
@media (min-width: 568px) {
  /* .footer-content-column {
      width: 49.99%;
  } */
}


/* footer end */

/* first page timeline start */
.timer-section {
  min-height: 100vh;
}
.timer-content p{
  font-family: 'munich';
}

.timer-section .container {
  padding-left: 15px;
  padding-right: 15px;
}

.timer-section .timer-container {
  list-style: none;
}

.timer-section .timer {
  list-style: none;
  position: relative;
  max-width: 750px;
  margin: auto;
}

.timer-section .timer::before {
  position: absolute;
  height: 100%;
  width: 2px;
  background-color: #F2F2F2;
  left: 50%;
  transform: translateX(-50%);
}

.timer-section .timer-step {
  width: 50%;
  padding: 20px 15px;
  position: relative;
  background-color: #F2F2F2;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .04);
}

.timer-section .timer-step:not(:first-child),
.timer-section .timer-step:not(:last-child) {
  margin: 70px 0 10px 0;
}

.timer-section .timer-step:nth-child(odd) {
  transform: translateX(100%);
  border-left: 2px solid lightblue;
  border-radius: 0 3px 3px 0;
}

.timer-section .timer-step:nth-child(even) {
  border-right: 2px solid lightsalmon;
  border-radius: 3px 0 0 3px;
}

.timer-section .timer-step > span {
  position: absolute;
  height: 26px;
  width: 25px;
  color: #333;
  background-color: #F2F2F2;
  text-align: center;
  line-height: 22px;
  border-radius: 3px;
  font-size: 20px;
  font-family: 'dosis'
}

.timer-section .timer-step:nth-child(odd) > span {
  top: -40px;
  left: -13px;
  border: 2px solid lightblue;
}

.timer-section .timer-step:nth-child(even) > span {
  top: -40px;
  right: -13px;
  border: 2px solid lightsalmon;
}

.timer-section .timer-step h3 {
  margin-bottom: 15px;
  font-family: 'sportz'
}

.timer-section .timer-step p {
  font-weight: 300;
  font-size: 17px;
  color: #555;
  font-family: 'dosis'
}

@media screen and (max-width: 500px) {
  .timer-section .timer-step {
    width: 100%;
    text-align: center;
    transform: translateX(0%);
    
    border-radius: 3px !important;
  }

  .timer-section .timer-step:nth-child(odd) {
    transform: translateX(0%);
    border: none;
    margin-left: -20px;
    border-top: 2px solid lightblue;
  }

  .timer-section .timer-step:nth-child(even) {
    border: none;
    margin-left: -20px;
    border-top: 2px solid lightsalmon;
  }

  .timer-section .timer-step span {
    left: 50% !important;
    transform: translateX(-50%);
  }
  .page1 {
    background-image: url('../img/Untitled\ design.jpg');
    background-position:center;
    background-size:cover;
    flex-shrink: 0;
    width: 100vw;
    height: 150vh;
  }
}
/* first page timeline end */

/* first page blog start */
.containerrr {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
  grid-auto-rows: 200px;
  grid-auto-flow: row dense;
  grid-gap: 23px;
  justify-content: center;
  background-color: #e7e7e7;
}

.card-left,
.card-right,
.card-bottom,
.card-top {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  background-color: #ffffff;
  width: 100%;
  border-radius: 95px;
  box-shadow: 0px 3px 9px 1px rgba(0, 10, 20, 0.2);
}

.card-top {
  flex-direction: column;
  grid-column: auto / span 1;
  grid-row: auto / span 2;
}

.card-right {
  flex-direction: row-reverse;
  grid-column: auto / span 2;
  grid-row: auto / span 1;
}

.card-bottom {
  flex-direction: column-reverse;
  grid-column: auto / span 1;
  grid-row: auto / span 2;
}

.card-left {
  flex-direction: row;
  grid-column: auto / span 2;
  grid-row: auto / span 1;
}

.card-image {
  display: flex;
  
}

.card-top .card-image,
.card-bottom .card-image {
  height: 50%;
  width: 100%;
}

.card-left .card-image,
.card-right .card-image {
  height: 100%;
  width: 50%;
}

.card-image img {
  width: 100%;
  object-fit: cover;
  border-radius: 95px;
}

.card-text {
  align-self: center;
  padding: 23px;
}

.card-top .card-text {
  height: auto;
  width: auto;
  padding-bottom: 36.8px;
}

.card-right .card-text {
  height: auto;
  width: 50%;
  padding-left: 36.8px;
}

.card-bottom .card-text {
  height: auto;
  width: auto;
  padding-top: 36.8px;
}

.card-left .card-text {
  height: auto;
  width: 50%;
  padding-right: 36.8px;
}

.card-text h3 {
  margin: 0;
  line-height: 1.35em;
  color: #334455;
  font-size: 17px;
  font-family: 'sportz'

}

@media (max-width: 500px) {
  .card-top {
    flex-direction: row;
    grid-column: auto / span 2;
    grid-row: auto / span 1;
  }

  .card-bottom {
    flex-direction: row-reverse;
    grid-column: auto / span 2;
    grid-row: auto / span 1;
  }

  .card-top .card-image,
  .card-bottom .card-image {
    height: 100%;
    width: 50%;
  }

  .card-top img {
    border-radius: 95px 0 0 95px;
  }

  .card-bottom img {
    border-radius: 0 95px 95px 0;
  }

  .card-top .card-text {
    height: auto;
    width: 50%;
    padding-right: 36.8px;
  }

  .card-bottom .card-text {
    height: auto;
    width: 50%;
    padding-left: 36.8px;
  }
}

@media (max-width: 400px) {
  .card-image {
    width: 38% !important;
  }
  
  .card-text {
    width: 62% !important;
  }
}

/* first page blog end */

/* right corner card start */

.card {
  height:250px ;
  width: 500px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.card-content {
  display: flex;
  margin-bottom: 70px;
  
}

.image-container {
  flex: 1;
  overflow: hidden;
  
}

.image-container img {
  margin-top: 10px;
  width: 100%;
  height: auto;
}

.text-container {
  flex: 2;
  padding: 20px;
  font-family: 'sportz'
}

@media screen and (min-width:300px) and (max-width: 768px) {
  .text-container p{
    font-size: 15px !important;
    font-family: 'dosis-me';
  }
  .text-container h3{
    font-size: 18px;
  }
  .card {
    height: 300px;
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    margin-left: -35px !important;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
  .card-container {
    display: none;
    margin-left: 35px;
    position: fixed;
      bottom: 40px;
      right: 20px;
      z-index: 9999;
    }
    .book-now-button {
      margin-bottom: 440px !important;
      padding: 7px 15px !important;
      margin-top: -180px !important;
      margin-left: -200px !important;
    }
    .image-container img {
      margin-top: 90px;
      margin-left: 7px;
      width: 100%;
      height: auto;
    }
    
}

.text-container p{
  font-size: 18px;
  font-family: 'dosis-me';
}
.text-container h3{
  font-size: 20px;
}
/* .card-footer {
  background-color: #f8f9fa;
  padding: 29px;
  text-align: right;
  margin-top: -90px;
} */

.book-now-button {
  background-color: #000000;
  color: #fff;
  border: none;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  padding: 7px 38px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 140px;
  position: relative; /* Add this to adjust button positioning */
  z-index: 1; 
  margin-top: -160px;
  margin-left: -350px ;
}

.book-now-button:hover {
  background-color: #0056b3;
}
.card-container {
display: none;
position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 9999;
}

.card-container.show {
display: block;
}
/* @media (max-width: 768px) {
.card {
    max-width:300px;
    max-height: 100px;
}
.text-container {
  flex: 1;
}
.image-container img {
  width: 50px;
  height: 70px;
}
}

@media (max-width: 576px) {
.card {
    max-width: 200px;
    max-height: 100px;
  }
.text-container {
  flex: 1;
}
.text-container h3 {
  font-size: 18px;
}
.text-container p {
  font-size: 10px;
}
.image-container img {
  width: 50px;
  height: 70px;
}
} */

/* right corner card end */