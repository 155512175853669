@font-face {
  font-family: "Computer Says No";
  src: url('../font/computer-says-no.ttf') format('truetype');
  /* Add other font formats (e.g., .ttf) if necessary */
}
body{ 
 
}
.cardddd {
  width: 340px;
  height: 610px;
  margin: 0 auto;
  background-color: #F8FBFE;
  border-radius: 8px;
  z-index: 1;
  position: relative;
}
 .carddssubd{
  padding-top: 90px;
  padding-bottom: 50px;
 }
.tools {
  display: flex;
  align-items: center;
  padding: 9px;
}
 
.circle {
  padding: 0 4px;
}
 
.box {
  display: inline-block;
  align-items: center;
  width: 10px;
  height: 10px;
  padding: 1px;
  border-radius: 50%;
}
 
.red {
  background-color: #ff605c;
 
}
 
.yellow {
  background-color: #ffbd44;
}
 
.green {
  background-color: #00ca4e;
}

.cardddd__content {
  padding: 10px;
  text-align: start;
}
 
.cardddd__content img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}
.cardddd__content p {
  font-size: 20px;
  padding-top: 12px;
  font-family: 'dosis-me';
  justify-content: space-between;
  line-height: 1.5; /* Adjust the line-height value as needed */
}

.cardddd__content .linkto {
  font-size: 24px;
  line-height: 100%;
 }

 .typing-demo {
  width: 55ch;
  animation: typing 4s steps(19) infinite, blink 5s step-end infinite alternate; /* Adjust the duration to 10s */
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: 'munich';
  font-weight: bold;
  font-size: 1.5em;
}


@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.linkto{
  position: absolute;
  left:0;
  bottom: 0;
}
.linktoo{
  position: absolute;
  right:0;
  bottom: 0;
}

.linkto p {
  font-size: 24px;
  line-height: 100%;
  display: flex;
  align-items: center;
}

.linkto p .arrow {
  margin-left: 5px; /* Adjust the margin as needed */
  font-size: 20px; /* Adjust the font size as needed */
}

.linkto p:hover{
  text-decoration: none;
}

.cardrow{
  padding: 10px;
  width: 90vw;
  overflow: hidden;
  flex-direction: row;
}


.backbutton{
  color: rgb(0, 0, 0);
  font-size: 30px;
  margin-left: 15px;
  margin-top: 18px;
  margin-right: 10px;
 }

 .backtext{
  color: rgb(0, 0, 0);
  font-size: 25px;
  font-weight: bold;
  margin-top: 15px;
  position: absolute;
 }

 .cardmarginnn {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: 'url("../img/Untitled design.jpg")';
  width: 100%;
  /* Set height to fill the viewport */
  
}

@media (max-width: 768px) {
  /* Adjust background size for smaller screens */
  .cardmarginnn {
     
  }
  .cardddd__content p {
    font-size: 16px !important;
    font-family: 'dosis-me';
    line-height: 100%;
   }
   .cardddd {
    width: 300px !important;
    height: 610px;
    margin: 0 auto;
    background-color: #F8FBFE;
    border-radius: 8px;
    z-index: 1;
    position: relative;
  }
}



 .button2 {
  font-size: 18px;
  text-transform: uppercase;
  display: inline-block;
  font-family: 'dosis-me';
  text-align: center;
  font-weight: bold;
  border: 0px solid #000000;
  position: relative;
  color: #000000;
  text-decoration: none;
  transition: 0.3s ease all;
  z-index: 1;
  height: 40px;
  width: 145px;
  padding-top: 5px;
  padding-right: 10px;
}

.button2:before {
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  opacity: 0;
  content: '';
  background-color: #000000;
  z-index: -1;
}

.button2:hover, .button2:focus {
  color: white;
}

.button2:hover:before, .button2:focus:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
}

.button2:active {
  transform: scale(0.9);
}

@media (300px <= width <= 600px) {
  .cardrow{
    flex-direction: column;
  }

  .cardddd__content {
    padding: 5px;

    text-align: start;
  }
   
  .cardddd__content img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  .cardddd__content p {
   font-size: 22px;
   line-height: 100%;
  }
  .cardddd__content .linkto {
    font-size: 24px;
    line-height: 100%;
   }
  
  .typing-demo {
    width: 17ch;
    animation: typing 3s steps(22) infinite, blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    font-size: 1.5em;
  }
  .cardddd{
    margin-bottom: 20px;
    
  }
}
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px; /* Adjust margin as needed */
}

.circle .box {
  width: 15px; /* Set the desired width */
  height: 15px; /* Set the desired height */
 
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; /* Set width to 100% */
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* ensure it appears above other content */
}


.popup-inner {
  background-color: white;
  padding: 120px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* add shadow for depth */
}

.popup-inner h2 {
  margin-top: 0;
  
  font-family: 'sportz';
 
}
.popup-inner label {
  margin-left: -50px;
  font-family: 'dosis-me';
  font-size: 16px; 
  color: #333;
}

.popup-inner form {
  display: flex;
  flex-direction: column;
}

.popup-inner label {
  margin-bottom: 10px;
}

.popup-inner input {
  padding: 18px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 130%;
  margin-left: -30px;
  margin-top: 4px;
}

.popup-inner button {
  padding: 8px 16px;
  background-color: #007bff; /* blue color */
  color: white;
  border: none;
  border-radius: 4px;
  font-family: 'dosis-me';
  font-size: 16px; 
  margin-left: -60px;
  cursor: pointer;
  margin-top: 10px;
}


.popup-inner button {
  width: 140%; /* Make the button full width */
}

.popup-inner button:hover {
  background-color: #007bff; /* darker shade of blue */
}


.checkbox-container label {
  margin-left: 5px;
}

.submit-button {
  background-color: #007bff; /* Example color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3; /* Darker color on hover */
}

/* are u experience css  start */
.btn2 {
  position: relative;
  display: inline-block;
  padding: 10px 15px;
  border: 2px solid #fefefe;
  text-transform: uppercase;
  color: #000000;
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
  transition: 0.3s;
}

.btn2::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% - -2px);
  transition: 0.3s ease-out;
  transform: scaleY(1);
}

.btn2::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% - 50px);
  background-color: #000000;
  transition: 0.3s ease-out;
  transform: scaleY(1);
}

.btn2:hover::before {
  transform: translateY(-25px);
  height: 0;
}

.btn2:hover::after {
  transform: scaleX(0);
  transition-delay: 0.15s;
}

.btn2:hover {
  border: 2px solid #fefefe;
}

.btn2 span {
  position: relative;
  z-index: 3;
  margin-top: 290px;
}

.spn2{
   margin-top: -70px;
}

.buttonnnn {
  text-decoration: none;
  border: none;
  background-color: transparent;
}
/* are u experience css  end */