.popup-container {
  position: fixed;
  bottom: 0;
  left: 100px;
  transform: translateY(100%);
  transition: transform 0.5s ease-in-out;
  z-index: 999;
}

.popup-container.show {
  transform: translateY(0);
}
 
.dream-cloud {
  position: absolute;
  top: -10%;
  left: 0%;
  width: 180%;
  height: 280px; /* Adjust the height as needed */
  background-image: url('../img/img1 (3).png'); /* Replace with the URL of your dream cloud image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000; /* Color of the cloud message */
  font-size: 22px; /* Font size of the cloud message */
  text-align: center;
  padding: 20px;
  mix-blend-mode: multiply;
  opacity: 0; /* Initially hidden */
  transition: opacity 1s ease-in-out, transform 5s ease-in-out; /* Adjust the duration and easing as needed */
  pointer-events: none;
 
}
 
.popup-container.show .dream-cloud {
  opacity: 1; /* Visible when the popup is shown */
  pointer-events: auto;
}
 
.dream-cloud.move-right {
  transform: translateX(80%); /* Adjust the distance to move the cloud to the right */
}
 
.robot-image {
  width: 100%;
  height: auto;
  max-width: 150px;
  margin-top: 150px; /* Adjust the margin-top to match the dream cloud height */
  margin-bottom: 10px;
  transition: transform 5s ease-in-out; /* Adjust the duration and easing as needed */
}
 
.robot-image.move-right {
  transform: translateX(130%); /* Adjust the distance to move the robot to the right */
}
 
 
.experience-message {
  position: absolute;
  top: 20%;
  left: -180%;
  transform: translate(-50%, -50%);
  color: #000000;
  font-size: 22px;
  text-align: center;
  padding: 20px;
  mix-blend-mode: multiply;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  pointer-events: none;
}
 
.popup-container.show .experience-message {
  opacity: 1;
  pointer-events: auto;
}
 
.cloud-message {
  margin-bottom: 20px;
  margin-left: 10px;
  animation: floatMotion 5s infinite ease-in-out;
}
 
@keyframes floatMotion {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
 
 
.home-video {
  width: 100%;
  height: 100vh;
}

.main-heading{
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  color: aliceblue;
}
 
/* .main-sub-heading{
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: start;
  height: 100vh;
  padding-top: 100px;
}

.main-sub-heading p {
  border-radius: 20px;
  font-size: 70px;
} */

.imagee{
  width: 150px;
  position: relative;
  left: 110px;
  top:30px;
  z-index: 1001;
}
 
@media screen and (max-width: 768px) {
  .imagee {
    left: 17%; /* Move the logo to the center horizontally */
    transform: translateX(-50%); /* Adjust the position to center */
    top: 16px; /* Maintain the top position */
    width: 100px;
   
  }
}

.main-sub-heading h2{
  font-size: 57px;
}
 
.boxxx {
  height: 100vh;
}
 
.page {
  background-image: url('../img/bg\ main.png');
  background-position:center;
  background-size:cover;
  flex-shrink: 0;
  width: 100vw;
  height: 100vh;
}


@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
 
.welcome-animation {
  animation: fadeInUp 1s ease-in-out;
}
 
.page22{
  z-index: 9999;
}
 

 
/* home.css */
 
 
/* Additional styling for smaller screens */
@media (max-width: 600px) {
  .fresher-cloud.move-right,
  .experienced-cloud.move-right {
    transform: translateX(0%);
  }
 
  .robot-imagee.move-right {
    transform: translateX(0%);
  }
}
 
.robot-image.move-to-center {
  transition: transform 0.5s ease;
  transform: translateX(400%);
}
 
 
.frsherorexpbox{
  height: 370px;
  width: 280px;
  transition: transform 0.5s ease;
  transform: translateX(400%);
  background-image: url('../img//img1\ \(1\).png');
  background-position: center;
  background-size: cover;
}
 
.message-textt{
  padding-top: 110px;
}
 
.frsherorexpbox button {
  font-size: 15px;
  background-color: black;
  color: white;
  border-radius: 5px;
  border-style: none;
}


@media (300px <= width <= 600px) {
  .main-sub-heading p {
    padding: 15px;
    border-radius: 20px;
    font-size: 23px;
    font-weight: bolder;
    border-color: #000000;
   
  }
  .robot-image.move-right {
    transform: translateX(0%); /* Adjust the distance to move the robot to the right */
  }
  .dream-cloud.move-right {
    transform: translateX(0%); /* Adjust the distance to move the cloud to the right */
  }

  .robot-image.move-to-center {
    transition: transform 0.5s ease;
    transform: translateX(-70%);
  }
  .fresher-cloud.move-right,
  .experienced-cloud.move-right {
    transform: translateX(0%);
  }
  .frsherorexpbox{
    height: 380px;
    width: 250px;
    transition: transform 0.5s ease;
    transform: translateX(140%);
    background-image: url('../img//img1\ \(1\).png');
    background-position: center;
    background-size: cover;
  }
 
   
  }





  /* blog css start */
  .pb-100 {
    padding-bottom: 100px;
  }
  .pt-100 {
    padding-top: 100px;
  }
  .mb-100 {
    margin-bottom: 100px;
  }
  a {
    text-decoration: none;
    color: #333;
    -webkit-transition: .4s;
    transition: .4s;
  }
  .section-title {
    position: relative;
  }
  .section-title p {
    font-size: 26px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin-bottom: 5px;
    font-weight: 400;
  }
  .section-title h4 {
    font-size: 40px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    position: relative;
    padding-bottom: 20px;
    display: inline-block;
  }
  .section-title h4::before {
    position: absolute;
    content: "";
    width: 80px;
    height: 2px;
    background-color: #d8d8d8;
    bottom: 0;
    left: 50%;
    margin-left: -40px;
  }
  .section-title h4::after {
    position: absolute;
    content: "";
    width: 50px;
    height: 2px;
    background-color: rgb(0, 0, 0);
    left: 0;
    bottom: 0;
    left: 50%;
    margin-left: -25px;
  }
  .blog-img {
    position: relative;
  }
  .blog-img img {
    width: 100%;
  }
  .post-category a {
    display: inline-block;
    background-color: #000000;
    color: #fff;
    border-radius: 25% 5% 25% 5%;
    font-size: 25px;
    font-family:'munich';
    margin-left: 50px;
    padding: 5px 15px;
  }
  .single-blog:hover img {
    opacity: .85;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)"
  }
  .post-category {
    position: absolute;
    left: 0;
    bottom: 0;
  }
 
  
  /* blog css end */

  /* refer and earn page css start */
  .whitebg {
    background-color: #ffffff;
  }
  
  .hero_section_wrapper {
    position: relative;
    background: #f8f8f8;
  }
  
  .main_page_section_width {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .margin_auto {
    margin: auto;
  }
  
  .flex_box_center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .displayblockat700 {
    display: block;
  }
  
  .image {
    position: relative;
  }
  
  .center_align {
    text-align: center;
  } 
  .center_alignn {
    text-align: center;
    width: 100%  !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
  }
  
  .mh {
    max-height: 100%;
  }
  
  .mw {
    max-width: 100%;
  }
  
  .p10 {
    padding: 10px;
  }
  
  .content_wrapper {
    position: relative;
    padding: 20px;
  }
  
  .flex_1 {
    flex: 1;
  }
  
  .full_h {
    height: 100%;
  }
  
  .title {
    line-height: 1.3;
    font-size: 30px;
  }
  
  
  
  .btns_wrapper {
    margin-top: 20px;
    margin-left: 0;
  }
  
  .buttonnnnnn {
    display: inline-block;
    border-radius: 4px;
    background-color: #3d405b4f;
    border: none;
    color: #000000;
    text-align: center;
    font-size: 24px;
    padding: 16px;
    width: 230px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
   }
   
   .buttonnnnnn span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
   }
   
   .buttonnnnnn span:after {
    content: '»';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -15px;
    transition: 0.5s;
   }
   
   .buttonnnnnn:hover span {
    padding-right: 15px;
   }
   
   .buttonnnnnn:hover span:after {
    opacity: 1;
    right: 0;
   }
  
  .everyOtherLink {
    background-color: transparent;
    color: blue; /* Adjust as needed */
  }
  
  .everyOtherSubtleButton {
    background-color: transparent;
    color: #333333; /* Adjust as needed */
    border: 1px solid #333333; /* Adjust as needed */
    padding: 8px 16px;
    border-radius: 5px;
  }
  
  .page_sections_wrapper {
    position: relative;
  }
  
  .page_sections {
    position: relative;
  }
  
  .page_section_wrapper {
    position: relative;
  }
  
  .page_section {
    position: relative;
  }
  
  .sec_title {
    text-align: center;
    font-size: 30px;
  }
  
  .p20 {
    padding: 20px;
  }
  
  .how_it_works_content .ico {
    width: 50px;
    height: 50px;
    margin: 0 auto;
  }
  
  .flex_box.center_align.displayblockat550 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .flex_1.flex_basis_3,
  .flex_1.flex_basis_4 {
    flex: 1;
  }
  
  .inline_top {
    vertical-align: center;
    text-align: center;
    display: grid;
    place-items: center;
  }
 
  .mt5 {
    margin-top: 5px;
    font-size:20px;
  }
  
  .ml5 {
    margin-left: 5px;
    font-size:20px;
  }
  
  .p15 {
    padding: 15px;
  }
  
  .radius5 {
    border-radius: 5px;
  }
  .description{
    font-size: 26px;
    color: #00000099;
  }
  .tttt{
   font-size: 36px;
   color: #000000;

  }
  .ddddd{
    font-size: 24px;
    color: #00000093;

  }
  @media (min-width: 320px) and (max-width: 480px) {
    .flex_box.row {
      flex-direction: column;
    }
  
    .flex_1 {
      flex: 1;
      margin-bottom: 20px; 
    }
  
    .flex_basis_3 {
      flex-basis: 100%; 
    }
  
    .col-lg-4,
    .col-md-12,
    .col-md-6 {
      width: 100%; 
    }

  }

  /* refer and earn page css end */


  /* about us page  css start */
  .responsive-container-block {
    min-height: 75px;
    height: fit-content;
    width: 100%;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    justify-content: flex-start;
  }
  
  .text-blk {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    line-height: 25px;
  }
  
  .responsive-container-block.bigContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-right: 50px;
    padding-bottom: 10px;
    padding-left: 50px;
  }
  
  .text-blk.headingText {
    font-size: 43px;
    font-weight: 700;
    line-height: 28px;
    color: rgb(0, 135, 177);
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
    padding-top: 0px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 0px;
  }
  
  .allText {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    width: 50%;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  
  .text-blk.subHeadingText {
    color: rgb(102, 102, 102);
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
    padding-top: 0px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 0px;
  }
  
  .text-blk.description {
    font-size: 18px;
    line-height: 28px;
    color: rgb(153, 153, 153);
    padding-top: 0px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 0px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 40px;
    margin-left: 0px;
  }
  
  .explore {
    font-size: 18px;
    line-height: 28px;
    color: rgb(255, 255, 255);
    background-color: rgb(244, 152, 146);
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-top-style: outset;
    border-right-style: outset;
    border-bottom-style: outset;
    border-left-style: outset;
    border-top-color: rgb(244, 152, 146);
    border-right-color: rgb(244, 152, 146);
    border-bottom-color: rgb(244, 152, 146);
    border-left-color: rgb(244, 152, 146);
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    cursor: pointer;
    box-shadow: rgba(244, 152, 146, 0.25) 0px 10px 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-top: 9px;
    padding-right: 45px;
    padding-bottom: 9px;
    padding-left: 45px;
  }
  
  .explore:hover {
    background-image: initial;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: rgb(255, 235, 234);
    color: rgb(244, 152, 146);
  }
  
  .responsive-container-block.Container {
    margin-top: 80px;
    margin-right: auto;
    margin-bottom: 50px;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    max-width: 1320px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
  }
  
  .responsive-container-block.Container.bottomContainer {
    flex-direction: row;
    margin-top: 50px;
    margin-right: auto;
    margin-bottom: 50px;
    margin-left: auto;
  }
  
  .allText.aboveText {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 40px;
  }
  
  .allText.bottomText {
    margin-top: 0px;
    margin-right: 10px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  
  .mainVideo {
    width: 85%;
    height: 450px;
    margin-top: -30px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  
  .videoContainer {
    width: 46%;
    height: 600px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 10px;
  }
  
  .mainVideo {
    z-index: 10;
  }
  
  .dotsImg {
    position: absolute;
    width: 80%;
    height: 600px;
    top: 0px;
    right: 0px;
    object-fit: cover;
  }
  
  @media (max-width: 1024px) {
    .responsive-container-block.Container {
      max-width: 850px;
    }
  
    .text-blk.description {
      font-size: 18px;
    }
  
    .allText {
      width: 40%;
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 20px;
    }
  
    .responsive-container-block.bigContainer {
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
    }
  
    .text-blk.subHeadingText {
      font-size: 30px;
    }
  
    .responsive-container-block.Container.bottomContainer {
      margin-top: 80px;
      margin-right: auto;
      margin-bottom: 50px;
      margin-left: auto;
    }
  
    .responsive-container-block.Container {
      max-width: 830px;
    }
  
    .allText.aboveText {
      margin-top: 30px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 40px;
    }
  
    .allText.bottomText {
      margin-top: 30px;
      margin-right: 40px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .allText.bottomText {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      margin-right: 0px;
      margin-bottom: 30px;
      margin-left: 0px;
    }
  
    .responsive-container-block.Container.bottomContainer {
      flex-direction: column;
    }
  
    .text-blk.headingText {
      text-align: center;
      font-size: 22px;
    }
  
    .text-blk.subHeadingText {
      text-align: center;
    }
  
    .text-blk.description {
      text-align: center;
    }
  
    .explore {
      margin-top: 0px;
      margin-right: auto;
      margin-bottom: 0px;
      margin-left: auto;
    }
  
    #inc0s {
      margin-top: 0px;
      margin-right: auto;
      margin-bottom: 0px;
      margin-left: auto;
    }
  
    .dotsImg {
      width: 50%;
      left: 50%;
      transform: translateX(-50%);
    }
  
    .videoContainer {
      width: 100%;
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
    }
  
    .mainVideo {
      width: 100%;
    }
  
    .allText.bottomText {
      margin-top: 30px;
      margin-right: 0px;
      margin-bottom: 60px;
      margin-left: 0px;
    }
  
    .text-blk.description {
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
    }
  
    .text-blk.headingText {
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
    }
  
    .text-blk.subHeadingText {
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
    }
  
    .mainVideo {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  }
  
  @media (max-width: 768px) {
    .allText {
      width: 100%;
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
    }
  
    .responsive-container-block.Container {
      flex-direction: column;
      height: auto;
    }
  
    .text-blk.headingText {
      text-align: center;
    }
  
    .text-blk.subHeadingText {
      text-align: center;
      font-size: 28px;
      line-height: 38px;
    }
  
    .text-blk.description {
      text-align: center;
      font-size: 18px;
      line-height: 27px;
    }
  
    .allText {
      margin-top: 40px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .allText.aboveText {
      margin-top: 40px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .responsive-container-block.Container {
      margin-top: 80px;
      margin-right: auto;
      margin-bottom: 50px;
      margin-left: auto;
    }
  
    .responsive-container-block.Container.bottomContainer {
      margin-top: 50px;
      margin-right: auto;
      margin-bottom: 50px;
      margin-left: auto;
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
  
    .allText.bottomText {
      margin-top: 40px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .allText.bottomText {
      margin-top: 40px;
      margin-right: 0px;
      margin-bottom: 60px;
      margin-left: 0px;
    }
  
    .mainVideo {
      height: 400px;
    }
  
    .videoContainer {
      height: 550px;
    }
  
    .explore {
      font-size: 16px;
      padding-top: 8px;
      padding-right: 40px;
      padding-bottom: 8px;
      padding-left: 40px;
    }
  
    .responsive-container-block.bigContainer {
      padding-top: 0px;
      padding-right: 50px;
      padding-bottom: 0px;
      padding-left: 50px;
    }
  }
  
  @media (max-width: 500px) {
    .responsive-container-block.Container {
      padding-top: 10px;
      padding-right: 0px;
      padding-bottom: 10px;
      padding-left: 0px;
      width: 100%;
      max-width: 100%;
    }
  
    .responsive-container-block.bigContainer {
      padding-top: 10px;
      padding-right: 25px;
      padding-bottom: 10px;
      padding-left: 25px;
    }
  
    .allText {
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      width: 100%;
    }
  
    .responsive-container-block.bigContainer {
      padding-top: 10px;
      padding-right: 20px;
      padding-bottom: 10px;
      padding-left: 20px;
    }
  
    .explore {
      font-size: 16px;
      line-height: 25px;
      padding: 8px 35px 8px 35px;
    }
  
    .dotsImg {
      width: 80%;
      height: 100%;
    }
  
    .mainVideo {
      height: 260px;
    }
  
    .videoContainer {
      height: 320px;
    }
  
    .responsive-container-block.Container.bottomContainer {
      padding: 0 0 0 0;
    }
  
    .text-blk.description {
      font-size: 16px;
      line-height: 23px;
      margin: 0 0 30px 0;
    }
  
    .text-blk.subHeadingText {
      font-size: 24px;
    }
  }
  /* about us page  css end */