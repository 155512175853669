.pass {
  max-width: 1200px;
  margin: 20px auto; /* Add top and bottom margin */
  padding: 20px;
  background-color: #f9f9f9;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items to the start (left) */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
 


.passbox {
  background-image: url('../img/Untitled\ design.jpg');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.pass h2 {
  font-size: 24px;
  font-family: 'sportz';
  margin-bottom: 20px;
  color: #333;
}
/* pass.css */
.button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'dosis-me';
  font-size: 16px;
}
.button-container {
  display: flex;
  justify-content: center; /* Center button horizontally */
}

.button:hover {
  background-color: #00b34b;
  color: #fff;
}

.pass p {
  font-size: 16px;
  font-family: 'dosis-me';
  line-height: 1.6;
  margin-bottom: 20px;
  color: #555;
}

.pass ul,
.pass ol {
  margin-bottom: 20px;
  padding-left: 20px;
}

.pass li {
  font-size: 16px;
  font-family: 'dosis-me';
  line-height: 1.6;
  margin-bottom: 10px;
  color: #555;
}

.footer {
  text-align: center;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #ddd;
  color: #777;
}

/* You can add more styles as needed */
