@font-face {
  font-family: "Computer Says No";
  src: url('../font/computer-says-no.ttf') format('truetype');
  /* Add other font formats (e.g., .ttf) if necessary */
}
body{

}
  #timeline {
    background-image: url("../img/timelinebg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .timeline {
    height: 100%;
    position: relative;
  }
  .timeline canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .timeline figcaption {
    font-weight: 400;
      font-size: 24px;
    text-transform: uppercase;
    -webkit-text-stroke: .25px;
  }
  .timeline h2 {
    font-weight: 400;
      font-size: 48px;
    padding-bottom: 60px;
    color: #7aabe0;
      text-transform: uppercase;
      text-align: center;
      text-decoration: underline;
  }

  .timeline h6 {
    color: #0090F5;
      font-size: 37px;
      font-weight: 400;
  }
  .timeline p,
  .timeline ol {
    font-weight: 400;
    padding: 3px 0 20px 0;
    color: #000000;
    text-align: left;
    width: 70%;
    mix-blend-mode: difference;
    font-size: 27px;
    line-height: 100%;
  }
  .timeline ol {
    list-style: disc;
    margin-top: -20px;
    padding-left: 40px;
  }
  .timeline figure {
    float: right;
    width: 100%;
  }
  .timeline article {
    position: relative;
    width: 38%;
    overflow: hidden;
    margin-bottom: 100px;
  }
  .timeline article:first-of-type {
    float: left;
    text-align: right;
  }
  .timeline article:first-of-type p,
  .timeline article:first-of-type figure {
    float: right;
  }
  .timeline article:last-of-type {
    float: right;
  }
  .timeline article:last-of-type h2 {
    color: #c6e0aa;
  }
  .timeline article:last-of-type h6,
  .timeline article:last-of-type a {
    color: #40aa00;
  }
  .timeline article:last-of-type a:hover {
    color: #95D40D;
  }
  


  .container {
    width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 3px;
    border: 1px solid #396afc;
    background: linear-gradient(to right, #005c97, #363795);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  
  }
  
  .container form .descr {
    font-size: 30px;
    font-weight: 500;
    color: #e8e8e8;
    padding-top: 15px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .input, textarea {
    margin: 1em 0 1em 0;
    width: 300px;
    position: relative;
  }
  
  .input input, textarea {
    font-size: 25px;
    outline: none;
    color: #e8e8e8;
    border: none;
    border-bottom: 2px solid #e8e8e8;
    background: transparent;
    border-radius: none;
    width: 100%;
    resize: none;
  }
  
  
  .input label {
    font-size: 100%;
    position: absolute;
    left: 0;
    color: #e8e8e8;
    padding: 0.7em;
    margin-left: 0.1em;
    font-size: 25px;
    pointer-events: none;
    transition: all 0.5s ease;
    text-transform: uppercase;
  }
  
  .input :is(input:focus, input:valid)~label {
    transform: translateY(-50%) scale(.9);
    margin: 0em;
    padding: 0.4em;
    background: transparent;
  }
  
  .input textarea:focus ~ label,
  .input textarea:valid ~ label {
    transform: translateY(-50%) scale(.9);
    margin: 0em;
    padding: 0.4em;
    background: transparent;
  }
  
  .inputGroup :is(input:focus, input:valid) {
    border-color: rgb(37, 37, 211);
  }
  
  .form button {
    color: #e8e8e8;
    font-size: 20px;
    align-self: flex-start;
    padding: 0.6em;
    border: none;
    cursor: pointer;
    margin-bottom: 50px;
    background: transparent;
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
  }
  
  .form button:before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #e8e8e8;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .form button:hover:before {
    opacity: 0.2;
  }
  
  .form button:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
.contactusss{
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 999;
}  
.closss{
  padding-top: 28px;
  color: white;
  font-size: 23px;
  cursor: pointer;
}
.closss:hover{
  color: #d01111;
}



.contactusbtn {
  height: 1.7em;
  width: 4em;
  background: #016dd9;
  -webkit-animation: jello-horizontal 0.9s both;
  animation: jello-horizontal 0.9s both;
  border: 2px solid #016dd9;
  outline: none;
  color: #fcfcfc;
  cursor: pointer;
  font-size: 25px;
  border-radius:10px;
}

.contactusbtn:hover {
  background: #016dd9;
  color: #ffffff;
  animation: squeeze3124 0.9s both;
  
}

@keyframes squeeze3124 {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.contactbtnnn{
  text-align: end;
  margin-bottom: 20px;
  margin-right: 30px;
  position: fixed; 
  bottom: 20px;
  right: 30px; 
  z-index: 999; 
}

@media (300px <= width <= 600px) {
  .container {
    width: 320px;
   padding-left: 30px;
   padding-right: 30px;
  }
  .contactbtnnn{
    text-align: end;
    margin-bottom: 18px;
    margin-right: 1px;
    position: fixed; 
    bottom: 15px;
    right: 25px; 
    z-index: 999; 
  }
  .timeline h2 {
    font-weight: 400;
      font-size: 38px;
    padding-bottom: 30px;
    color: #7aabe0;
  }
  .timeline h6 {
    color: #0090F5;
      font-size: 27px;
      font-weight: 400;
  }

  .timeline p,
  .timeline ol {
    font-weight: 400;
    padding: 3px 0 20px 0;
    color: #000000;
    text-align: left;
    width: 90%;
    mix-blend-mode: difference;
    font-size: 22px;
    line-height: 100%;
  }
  .timeline article {
    position: relative;
    width: 45%;
    overflow: hidden;
    margin-bottom: 100px;
  }
}